import React from 'react'

import { Colors } from 'common-constants/colors'

export const ActivitySvg = ({
  stroke = Colors.link,
  circle = 'transparent',
}: {
  stroke?: string | Colors
  circle?: string | Colors
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke={stroke}
        strokeWidth="1.5"
        d="M9.064 3h6.404l-2.099 6.311H18L7.988 20.75l1.929-7.504h-3.6z"
      />
      <circle cx="22" cy="2" r="2" fill={circle} />
    </g>
  </svg>
)
