import { DEFAULT_LOCALE, isMamba } from 'common/constants'

import { useLocale } from './useLocale'
import { usePartner } from './usePartner'

export const useShowLinkBlog = () => {
  const { partnerId } = usePartner()
  const locale = useLocale()

  return isMamba(partnerId) && locale === DEFAULT_LOCALE
}
